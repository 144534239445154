.sprite2about {
    background-image: url(../images/spritesheet2.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite2about-1 {
    width: 630px;
    height: 708px;
    background-position: -5px -5px;
}

.sprite2about-10 {
    width: 630px;
    height: 708px;
    background-position: -645px -5px;
}

.sprite2about-11 {
    width: 630px;
    height: 708px;
    background-position: -1285px -5px;
}

.sprite2about-12 {
    width: 630px;
    height: 708px;
    background-position: -1925px -5px;
}

.sprite2about-13 {
    width: 630px;
    height: 708px;
    background-position: -2565px -5px;
}

.sprite2about-14 {
    width: 630px;
    height: 708px;
    background-position: -5px -723px;
}

.sprite2about-15 {
    width: 630px;
    height: 708px;
    background-position: -645px -723px;
}

.sprite2about-16 {
    width: 630px;
    height: 708px;
    background-position: -1285px -723px;
}

.sprite2about-17 {
    width: 630px;
    height: 708px;
    background-position: -1925px -723px;
}

.sprite2about-18 {
    width: 630px;
    height: 708px;
    background-position: -2565px -723px;
}

.sprite2about-19 {
    width: 630px;
    height: 708px;
    background-position: -5px -1441px;
}

.sprite2about-2 {
    width: 630px;
    height: 708px;
    background-position: -645px -1441px;
}

.sprite2about-20 {
    width: 630px;
    height: 708px;
    background-position: -1285px -1441px;
}

.sprite2about-21 {
    width: 630px;
    height: 708px;
    background-position: -1925px -1441px;
}

.sprite2about-22 {
    width: 630px;
    height: 708px;
    background-position: -2565px -1441px;
}

.sprite2about-23 {
    width: 630px;
    height: 708px;
    background-position: -5px -2159px;
}

.sprite2about-24 {
    width: 630px;
    height: 708px;
    background-position: -645px -2159px;
}

.sprite2about-25 {
    width: 630px;
    height: 708px;
    background-position: -1285px -2159px;
}

.sprite2about-26 {
    width: 630px;
    height: 708px;
    background-position: -1925px -2159px;
}

.sprite2about-27 {
    width: 630px;
    height: 708px;
    background-position: -2565px -2159px;
}

.sprite2about-28 {
    width: 630px;
    height: 708px;
    background-position: -5px -2877px;
}

.sprite2about-29 {
    width: 630px;
    height: 708px;
    background-position: -645px -2877px;
}

.sprite2about-3 {
    width: 630px;
    height: 708px;
    background-position: -1285px -2877px;
}

.sprite2about-4 {
    width: 630px;
    height: 708px;
    background-position: -1925px -2877px;
}

.sprite2about-5 {
    width: 630px;
    height: 708px;
    background-position: -2565px -2877px;
}

.sprite2about-6 {
    width: 630px;
    height: 708px;
    background-position: -3205px -5px;
}

.sprite2about-7 {
    width: 630px;
    height: 708px;
    background-position: -3205px -723px;
}

.sprite2about-8 {
    width: 630px;
    height: 708px;
    background-position: -3205px -1441px;
}

.sprite2about-9 {
    width: 630px;
    height: 708px;
    background-position: -3205px -2159px;
}

// sprite 2

.sprite2 {
    background-image: url(../images/spritesheet2.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite2-1 {
    width: 630px;
    height: 708px;
    background-position: -5px -5px;
}

.sprite2-10 {
    width: 630px;
    height: 708px;
    background-position: -645px -5px;
}

.sprite2-11 {
    width: 630px;
    height: 708px;
    background-position: -1285px -5px;
}

.sprite2-12 {
    width: 630px;
    height: 708px;
    background-position: -1925px -5px;
}

.sprite2-13 {
    width: 630px;
    height: 708px;
    background-position: -2565px -5px;
}

.sprite2-14 {
    width: 630px;
    height: 708px;
    background-position: -5px -723px;
}

.sprite2-15 {
    width: 630px;
    height: 708px;
    background-position: -645px -723px;
}

.sprite2-16 {
    width: 630px;
    height: 708px;
    background-position: -1285px -723px;
}

.sprite2-17 {
    width: 630px;
    height: 708px;
    background-position: -1925px -723px;
}

.sprite2-18 {
    width: 630px;
    height: 708px;
    background-position: -2565px -723px;
}

.sprite2-19 {
    width: 630px;
    height: 708px;
    background-position: -5px -1441px;
}

.sprite2-2 {
    width: 630px;
    height: 708px;
    background-position: -645px -1441px;
}

.sprite2-20 {
    width: 630px;
    height: 708px;
    background-position: -1285px -1441px;
}

.sprite2-21 {
    width: 630px;
    height: 708px;
    background-position: -1925px -1441px;
}

.sprite2-22 {
    width: 630px;
    height: 708px;
    background-position: -2565px -1441px;
}

.sprite2-23 {
    width: 630px;
    height: 708px;
    background-position: -5px -2159px;
}

.sprite2-24 {
    width: 630px;
    height: 708px;
    background-position: -645px -2159px;
}

.sprite2-25 {
    width: 630px;
    height: 708px;
    background-position: -1285px -2159px;
}

.sprite2-26 {
    width: 630px;
    height: 708px;
    background-position: -1925px -2159px;
}

.sprite2-27 {
    width: 630px;
    height: 708px;
    background-position: -2565px -2159px;
}

.sprite2-28 {
    width: 630px;
    height: 708px;
    background-position: -5px -2877px;
}

.sprite2-29 {
    width: 630px;
    height: 708px;
    background-position: -645px -2877px;
}

.sprite2-3 {
    width: 630px;
    height: 708px;
    background-position: -1285px -2877px;
}

.sprite2-4 {
    width: 630px;
    height: 708px;
    background-position: -1925px -2877px;
}

.sprite2-5 {
    width: 630px;
    height: 708px;
    background-position: -2565px -2877px;
}

.sprite2-6 {
    width: 630px;
    height: 708px;
    background-position: -3205px -5px;
}

.sprite2-7 {
    width: 630px;
    height: 708px;
    background-position: -3205px -723px;
}

.sprite2-8 {
    width: 630px;
    height: 708px;
    background-position: -3205px -1441px;
}

.sprite2-9 {
    width: 630px;
    height: 708px;
    background-position: -3205px -2159px;
}



/* ===== SUSU 2 ====== */

.sprite1 {
    background-image: url(../images/spritesheet.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite1-1 {
    width: 637px;
    height: 636px;
    background-position: -5px -5px;
}

.sprite1-10 {
    width: 637px;
    height: 636px;
    background-position: -652px -5px;
}

.sprite1-11 {
    width: 636px;
    height: 636px;
    background-position: -1299px -5px;
}

.sprite1-12 {
    width: 636px;
    height: 636px;
    background-position: -1945px -5px;
}

.sprite1-13 {
    width: 637px;
    height: 636px;
    background-position: -2591px -5px;
}

.sprite1-14 {
    width: 637px;
    height: 636px;
    background-position: -3238px -5px;
}

.sprite1-15 {
    width: 637px;
    height: 636px;
    background-position: -5px -651px;
}

.sprite1-16 {
    width: 637px;
    height: 636px;
    background-position: -652px -651px;
}

.sprite1-17 {
    width: 637px;
    height: 636px;
    background-position: -1299px -651px;
}

.sprite1-18 {
    width: 636px;
    height: 636px;
    background-position: -1946px -651px;
}

.sprite1-19 {
    width: 636px;
    height: 636px;
    background-position: -2592px -651px;
}

.sprite1-2 {
    width: 636px;
    height: 636px;
    background-position: -3238px -651px;
}

.sprite1-20 {
    width: 637px;
    height: 636px;
    background-position: -5px -1297px;
}

.sprite1-21 {
    width: 637px;
    height: 636px;
    background-position: -652px -1297px;
}

.sprite1-22 {
    width: 637px;
    height: 636px;
    background-position: -1299px -1297px;
}

.sprite1-23 {
    width: 637px;
    height: 636px;
    background-position: -1946px -1297px;
}

.sprite1-24 {
    width: 637px;
    height: 636px;
    background-position: -2593px -1297px;
}

.sprite1-25 {
    width: 637px;
    height: 636px;
    background-position: -3240px -1297px;
}

.sprite1-26 {
    width: 637px;
    height: 636px;
    background-position: -5px -1943px;
}

.sprite1-27 {
    width: 637px;
    height: 636px;
    background-position: -652px -1943px;
}

.sprite1-28 {
    width: 637px;
    height: 636px;
    background-position: -1299px -1943px;
}

.sprite1-29 {
    width: 636px;
    height: 636px;
    background-position: -1946px -1943px;
}

.sprite1-3 {
    width: 636px;
    height: 636px;
    background-position: -2592px -1943px;
}

.sprite1-30 {
    width: 636px;
    height: 636px;
    background-position: -3238px -1943px;
}

.sprite1-31 {
    width: 636px;
    height: 636px;
    background-position: -5px -2589px;
}

.sprite1-32 {
    width: 636px;
    height: 636px;
    background-position: -651px -2589px;
}

.sprite1-33 {
    width: 637px;
    height: 636px;
    background-position: -1297px -2589px;
}

.sprite1-34 {
    width: 637px;
    height: 636px;
    background-position: -1944px -2589px;
}

.sprite1-35 {
    width: 636px;
    height: 636px;
    background-position: -2591px -2589px;
}

.sprite1-36 {
    width: 637px;
    height: 636px;
    background-position: -3237px -2589px;
}

.sprite1-37 {
    width: 637px;
    height: 636px;
    background-position: -5px -3235px;
}

.sprite1-38 {
    width: 637px;
    height: 636px;
    background-position: -652px -3235px;
}

.sprite1-39 {
    width: 637px;
    height: 636px;
    background-position: -1299px -3235px;
}

.sprite1-4 {
    width: 636px;
    height: 636px;
    background-position: -1946px -3235px;
}

.sprite1-40 {
    width: 637px;
    height: 636px;
    background-position: -2592px -3235px;
}

.sprite1-41 {
    width: 637px;
    height: 636px;
    background-position: -3239px -3235px;
}

.sprite1-42 {
    width: 637px;
    height: 636px;
    background-position: -3885px -5px;
}

.sprite1-43 {
    width: 637px;
    height: 636px;
    background-position: -3884px -651px;
}

.sprite1-44 {
    width: 636px;
    height: 636px;
    background-position: -3887px -1297px;
}

.sprite1-45 {
    width: 636px;
    height: 636px;
    background-position: -3884px -1943px;
}

.sprite1-46 {
    width: 636px;
    height: 636px;
    background-position: -3884px -2589px;
}

.sprite1-47 {
    width: 636px;
    height: 636px;
    background-position: -3886px -3235px;
}

.sprite1-48 {
    width: 637px;
    height: 636px;
    background-position: -5px -3881px;
}

.sprite1-5 {
    width: 636px;
    height: 636px;
    background-position: -652px -3881px;
}

.sprite1-6 {
    width: 636px;
    height: 636px;
    background-position: -1298px -3881px;
}

.sprite1-7 {
    width: 636px;
    height: 636px;
    background-position: -1944px -3881px;
}

.sprite1-8 {
    width: 636px;
    height: 636px;
    background-position: -2590px -3881px;
}

.sprite1-9 {
    width: 637px;
    height: 636px;
    background-position: -3236px -3881px;
}


