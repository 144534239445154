@import "settings";
@import "margin";
@import "padding";
@import "fonts";
@import "milk";

@import 'https://use.fontawesome.com/releases/v5.0.8/css/all.css';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700';

body {
    font-size: 14px;
    font-family: 'Asap';
    font-weight: normal;
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #fff;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

.wrapper {
    padding-top: 120px;
    min-height: 700px;
}

@keyframes pageIn {
    0% {
        // transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        // transform: translateX(0);
        opacity: 1;
    }
}

body {
    animation: pageIn ease .3s;
}

body.preloader-site {
    overflow: hidden !important;
}

.preloader-wrapper {
    overflow: hidden !important;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(#0381CE, #30a2e8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;

    .preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .logo-svg {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // margin: -70px 0px 0px -60px;
            margin-top: -2rem;
            height: 7.5rem;
        }
    }
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.color-white {
    color: #fff !important;
}

.color-black {
    color: #000 !important;
}

.color-primary {
    color: $color-primary !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-22 {
    font-size: 22px !important;
}

.hidden {
    display: none !important;
}

label {
    line-height: normal;
}

label.error {
    color: rgba(255, 255, 255, .7);
    font-size: 13px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 0px;
}

label#inlineRadioOptions-error {
    position: absolute;
    bottom: -3px;
    left: 0px;
}

label#subscribe-error {
    position: absolute;
    bottom: -25px;
    left: 0px;
}

label#inlineRadioOptions-error~span {
    margin-bottom: 18px;
}

h1 {
    font-size: 34px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

h2 {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

h3 {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

h4 {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

hr.white {
    border-color: rgba(255, 255, 255, .2);
}

hr.short-line {
    width: 40px;
    margin-top: .3rem;
    margin-bottom: 1.4rem;
    border-width: 2px;
    border-color: $color-primary;
}

hr.short-line.left {
    width: 40px;
    margin: 0px;
    margin-top: .3rem;
    margin-bottom: 1.4rem;
    border-width: 2px;
    border-color: $color-primary;
}

input[type=text],
input[type=email],
input[type=password] {
    height: 36px;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    outline: none;
    box-shadow: none;
    border-radius: 2px;
    border: 1px solid #e4e4e4;
    font-size: 14px;
    padding: 10px 12px;
}

input:focus,
textarea:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $color-primary;
}


input::placeholder,
textarea::placeholder {
    font-size: 13px;
    color: rgba(0, 0, 0, .4);
}

button,
button {
    outline: none;
    box-shadow: none;
    border-color: transparent;
}

button:hover {
    cursor: pointer;
}

button:focus,
button:active {
    outline: none !important;
    box-shadow: none;
    border-color: transparent;
}

.btn-primary {
    outline: none;
    border-color: transparent;
    background-color: $color-green;
    color: #fff;
    font-size: 13px;
    padding: 6px 18px;
    font-weight: 600;
    border-radius: 20px;
    transition: .3s;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
    border-color: transparent;
    background-color: $color-green;
    opacity: .8;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

.btn-border-primary {
    outline: none;
    border: 1px solid $color-primary;
    background-color: transparent;
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    padding: 6px 10px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-border-primary.active {
    border-color: $color-primary;
    background-color: $color-primary;
    opacity: .87;
    color: #fff;
    pointer-events: none;
}

.btn-border-primary:hover,
.btn-border-primary:focus,
.btn-border-primary:active,
.btn-border-primary:not(:disabled):not(.disabled):active {
    border-color: $color-primary;
    background-color: $color-primary;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

.btn-border-secondary {
    outline: none;
    border: 1px solid #DFCC99;
    background-color: transparent;
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    padding: 6px 40px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-border-secondary.active {
    border-color: #DFCC99;
    background-color: #DFCC99;
    opacity: 1;
    color: #fff;
    pointer-events: none;
}

.btn-border-secondary:hover,
.btn-border-secondary:focus,
.btn-border-secondary:active,
.btn-border-secondary:not(:disabled):not(.disabled):active {
    border-color: #DFCC99;
    background-color: #DFCC99;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}


.btn-border-black {
    outline: none;
    border: 1px solid #000;
    background-color: transparent;
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    padding: 4px 30px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-border-black.active {
    border-color: #000;
    background-color: #000;
    opacity: 1;
    color: #fff;
    pointer-events: none;
}

.btn-border-black:hover,
.btn-border-black:focus,
.btn-border-black:active,
.btn-border-black:not(:disabled):not(.disabled):active {
    border-color: #000;
    background-color: #000;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

.btn-white {
    background-color: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;
    font-size: 13px;
    padding: 6px 40px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white:not(:disabled):not(.disabled):active {
    background-color: $color-primary;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

.img-fix {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.img-fix2 {
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.bg-light {
    background-color: $color-white !important;
}

.bg-white {
    background-color: $color-white !important;
}

.bg-primary {
    background-color: $color-primary !important
}

.bg-secondary {
    background-color: $color-secondary !important;
}

.bg-dark {
    background-color: $color-black !important;
}

.bg-black {
    background-color: $color-black !important;
}

.bg-gradient {
    background: linear-gradient(#0381CE, #30a2e8);
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}


/*content style begin*/

.container-custom {
    padding: 40px 150px;
}

.container-custom.candy-milk {
    padding: 40px 100px;
}

.navbar {
    font-size: 14px;
    padding: 0px 100px;
    font-weight: 300;
    font-family: 'Roboto';
}

.navbar {
    position: absolute;
    width: 100%;
    z-index: 2; // box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.1);

    .navbar-brand {
        img {
            height: 90px;
            margin: 10px 0px;
        }
    }

    .nav-link {
        font-family: 'Asap';
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        letter-spacing: .5px;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: normal;
    }

    .link-custom-line {
        margin-top: 5px;

        img.img-menu {
            height: 55px;
            margin-left: 40px;
        }

        .nav-link::after {
            content: "\2022";
            display: block;
            text-align: center;
            opacity: 0;
            font-size: 28px;
            line-height: .8;
        }
    }

    .link-custom-line.active {
        .nav-link {
            color: #C7D22C;
        }

        .nav-link::after {
            content: "\2022";
            opacity: 1;
            display: block;
            text-align: center;
            font-size: 28px;
            line-height: .8;
        }
    }
}


footer {
    background-color: #0481CE;
    color: #fff;

    .footer-section {
        padding: 40px 80px;
        padding-bottom: 30px;
    }

    .footer-section-bottom {
        color: $color-primary;
        background-color: #fff;
        padding: 8px 80px;
    }

    .shape-footer {
        position: absolute;
        left: 0px;
        top: 35px;

        img {
            height: 180px;
        }
    }
}


footer .copyright {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
}


footer .social-icon {
    a {
        color: #fff;
    }

    padding: 10px;
}

footer .social-icon i {
    font-size: 20px;
    margin: 5px 10px;
}

footer {
    .link-footer {
        font-size: 14px;
        line-height: 2;

        a {
            color: #fff;
            transition: .3s;
        }
    }

    .img-footer {
        height: 100px;
    }
}

.subscribe-box,
.search-box {
    input[type=text] {
        padding-left: 15px;
        padding-right: 15px;
    }

    .input-group>.form-control:not(:last-child) {
        font-size: 14px;
        outline: none;
        box-shadow: none;
        border-radius: 0px;
        border-right: 0px;
        border-radius: 20px 0px 0px 20px;
    }

    .input-group {
        input::placeholder {
            font-size: 12px;
            color: rgba(0, 0, 0, .4);
        }

        input:focus {
            outline: none;
            border-color: #e4e4e4;
        }

        button {
            border-radius: 0px 20px 20px 0px;
            outline: none;
            box-shadow: none;
            background-color: $color-green;
            border-color: $color-green;
            color: #fff;
            font-size: 13px;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    label#subscribe-error {
        position: absolute;
        top: -20px;
        left: inherit;
        right: 0px !important;
    }
}

.search-map {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  input[type=text] {
      padding-left: 15px;
      padding-right: 15px;
  }

  .input-group>.form-control:not(:last-child) {
      font-size: 14px;
      outline: none;
      box-shadow: none;
      border-radius: 0px;
      border-right: 0px;
      border-radius: 20px 0px 0px 20px;
  }

  .input-group {
      input::placeholder {
          font-size: 12px;
          color: rgba(0, 0, 0, .4);
      }

      input:focus {
          outline: none;
          border-color: #e4e4e4;
      }

      button {
        line-height: normal;
        border-radius: 0px 20px 20px 0px;
        outline: none;
        box-shadow: none;
        background-color: $color-green;
        border-color: $color-green;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
      }
  }

  label#subscribe-error {
      position: absolute;
      top: -20px;
      left: inherit;
      right: 0px !important;
  }
}



.breadcrumbs {
    padding: 15px 100px;
    font-weight: 600;
}

.breadcrumbs a {
    font-weight: 400;
    color: #000000;
}

.breadcrumbs i {
    color: rgba(0, 0, 0, 0.5);
    margin: 0px 10px;
}

.custom-select {
    border-radius: 2px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+) no-repeat 100% 50%;
    border: none;
    border: 1px solid #e4e4e4;
    outline: none;
    font-size: 13px;
    padding: 5px 12px;
    height: 36px;
    transition: .3s;
    color: rgba(0, 0, 0, .6);
}

.custom-select:focus {
    outline: none;
    border-color: $color-primary;
}


.box-shadow {
    box-shadow: 0 5px 17px 3px rgba(0, 0, 0, 0.1);
}

.home-section {
    .wrapper {
        padding-top: 0px;
    }

    .navbar {
        box-shadow: none;
        background: transparent;
    }
}

.icon-circle {
    height: 35px;
    width: 35px;
    text-align: center;
    display: inline-block;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 5px;

    i {
        font-size: 22px;
        line-height: 1.65;
    }

    i.fa-youtube {
        color: #F61C0D;
    }

    i.fa-facebook-f {
        color: #475993;
    }

    i.fa-instagram {
        color: transparent;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
        background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
        background-clip: text;
        -webkit-background-clip: text;
    }
}

.icon-circle-share {
    height: 28px;
    width: 28px;
    text-align: center;
    display: inline-block;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 5px;

    i {
        font-size: 16px;
        line-height: 1.8;
    }

    i.fa-twitter {
        color: #00aced;
    }

    i.fa-facebook-f {
        color: #475993;
    }
}

.link-custom-line.icon-social {
    margin-right: 30px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.title-banner {
    position: absolute;
    top: 20%;
    width: 100%;
    text-align: center;

    img {
        height: 120px;
    }
}

.about-milkita {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 900px;
}

.animation-section {
    min-height: 1500px;
}

.inline-image {
    img {
        height: 40px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.inline-image-big {
    img {
        height: 70px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.inline-image-logo {
    img {
        height: 180px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.animation-shapes1 {
    position: absolute;
    top: 9rem;
    left: 5%;
    width: 100%;

    .shape-animation {
        height: 150px;
    }
}

.animation-shapes2 {
    position: absolute;
    top: 9rem;
    left: 5%;
    width: 100%;

    .shape-animation {
        height: 150px;
    }
}

.animation-shapes3 {
    position: absolute;
    top: 9rem;
    left: 5%;
    width: 100%;

    .shape-animation {
        height: 150px;
    }
}

.animation-shapes4 {
    position: absolute;
    top: 9rem;
    left: 5%;
    width: 100%;

    .shape-animation {
        height: 150px;
    }
}

.social-table {
    .social-td {
        .part-social {
            padding: 1px;
            height: 220px;

            img {
                object-fit: cover;
                object-position: center;
            }
        }

        .part-social.colspan2 {}
    }
}

.slider-product-style {
    padding: 0px 70px;

    .owl-nav button {
        position: absolute;
        margin-top: -30px !important;
        line-height: 1;
        height: 40px;
        width: 40px;
        border: 2px solid $color-green !important;
        border-radius: 50% !important;
        padding: 20px;
    }

    .owl-nav button i {
        color: $color-green;
        font-size: 20px;
    }

    .owl-nav button.owl-next {
        top: 50%;
        line-height: 0;
        right: 0;
    }

    .owl-nav button.owl-prev {
        line-height: 0;
        top: 50%;
        left: 0;
    }

    .image-left {
        max-height: 600px;
    }

    .amazon-button {
        img {
            height: 50px;
            width: auto;
        }
    }
}

.section-candy1 {
    .img-candy1a {
        width: 90%;
    }

    .img-candy1b {
        margin-top: 150px;
        width: 90%;
    }
}

.section-candy2 {
    .img-candy2b {
        margin-top: 50%;
        width: 90%;
    }
}

.section-about {
    color: #fff;
    font-weight: 600;

    .about-title {
        height: 100px;
    }

    .about-candy {
        img {
            width: 70%;
        }
    }
}

.section-products {
    color: #fff;
    font-weight: 600;

    .products-title {
        height: 100px;
    }

    .products-candy {
        img {
            width: 70%;
        }
    }

    .products-list {
        .product-item {
            margin-bottom: 50px;

            img {
                height: 250px;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}

.section-contact {
    color: #fff;
    font-weight: 300;

    .contact-title {
        height: 100px;
    }

    .contact-form-box {
        padding: 20px;
        margin-bottom: 120px;
        background-color: #fff;
        border-radius: 200px;

        img {
            // height: 350px;
            width: 100%;
        }

        .form-padding {
            padding-right: 100px;
        }
    }
}

.section-gallery {
    min-height: 1400px;

    .grid-item {
        padding: 5px;
        width: 25%;

        img {
            width: 100%
        }
    }

    // .grid-item--width2 {
    //     width: 100%;
    // }
}

.section-blog {
    text-align: center;
    color: #fff;
}

.box-side-right {
    text-align: left;
    margin-top: 30px;
    border: 1px solid #fff;

    .head-box {
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
        color: $color-primary;
        background-color: #fff;
    }

    .body-box {
        padding: 10px;

        .item-post {
            img {
                width: 100%;
                height: 80px;
            }

            .row {
                margin-left: -5px;
                margin-right: -5px;

                .mini-padding {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.map-section {
  padding: 15px 20px;
  color: #404040;
  // background-color: #fff;
  #map-milkita,
  #mapid {
      width: 100%;
      height: 600px;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .sidebar {
    height: 600px;
    overflow: hidden;
    h1 {
      font-size: 22px;
      margin: 0;
      font-weight: 400;
      line-height: 20px;
      padding: 20px 2px;
    }
    
    a {
      color: #404040;
      text-decoration: none;
    }
    
    a:hover {
      color: #101010;
    }
    .heading {
      background: #fff;
      border-bottom: 1px solid #eee;
      padding: 15px;
    }
    
    .listings {
      height: 100%;
      overflow: auto;
      padding-bottom: 60px;
    }
    
    .listings .item {
      display: block;
      border-bottom: 1px solid #eee;
      padding: 10px;
      text-decoration: none;
    }
    
    .listings .item:last-child { border-bottom: none; }
    
    .listings .item .title {
      display: block;
      color: #00853e;
      font-weight: 700;
    }
    
    .listings .item .title small { font-weight: 400; }
    
    .listings .item.active .title,
    .listings .item .title:hover { color: #8cc63f; }
    
    .listings .item.active {
      background-color: #f8f8f8;
    }
    
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      border-left: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    
    ::-webkit-scrollbar-track {
      background: none;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #00853e;
      border-radius: 0;
    }
    
    .clearfix { display: block; }
    
    .clearfix::after {
      content: '.';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
  .mapboxgl-popup-close-button {
    display: none;
  }
  
  .mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
    padding: 0;
    width: 180px;
  }
  
  .mapboxgl-popup-content-wrapper {
    padding: 1%;
  }
  
  .mapboxgl-popup-content h3 {
    background: #91c949;
    color: #fff;
    margin: 0;
    display: block;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
  }
  
  .mapboxgl-popup-content h4 {
    margin: 0;
    display: block;
    padding: 10px;
    font-weight: 400;
  }
  
  .mapboxgl-popup-content div {
    padding: 10px;
  }
  
  .mapboxgl-container .leaflet-marker-icon {
    cursor: pointer;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
    margin-top: 15px;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: #91c949;
  }
  .marker {
    border: none;
    cursor: pointer;
    height: 50px;
    width: 50px;
    background-image: url('../images/marker.png');
    background-size: contain;
    background-color: rgba(0, 0, 0, 0);
  }
}

.leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}


button.navbar-toggler {
    background-color: #fff;
    border: 3px solid $color-green;
    border-radius: 50%;
    line-height: 0;
    padding: 5px;
    height: 40px;
    width: 40px;
    cursor: pointer;

    i {
        color: rgba(0, 0, 0, .7) !important;
        font-size: 16px;
    }
}

.jus-milkita-home {
    position: relative;

    img {
        z-index: 1;
        position: absolute;
        right: 18.5%;
        bottom: -115px;
        height: 900px;
    }
}

.susu1-milkita-home {
    position: relative;

    img {
        position: absolute;
        height: 550px;
        right: 20%;
        margin-right: 16px;
        margin-top: 65px;
    }
}

.susu2-milkita-home {
    position: relative;

    img {
        position: absolute;
        height: 850px;
        right: 20%;
        margin-right: 16px;
        margin-top: 65px;
    }
}

.sprite1 {
    position: absolute;
    right: 18%;
    margin-right: 15px;
    margin-top: 50px;
}

.sprite2 {
    position: absolute;
    left: 20%;
    margin-left: 80px;
    margin-top: 250px;
}

.sprite2about {
    z-index: -1;
    position: absolute;
    right: -25vw;
    margin-left: 0px;
    margin-top: 0px;
}

.main-banner {
    position: relative;
}

.banner-layer2 {
    position: absolute;
    bottom: 0px;
}

.susu-about1 {
    position: absolute;
    top: -2px;

    img {
        width: 40vw;
    }
}

.susu-contact {
    position: absolute;
    margin-top: 50px;
    right: 0px;

    img {
        width: 30vw;
    }
}

.btn-buy {
    padding: 6px 25px;
}

.magnifier-preview.custom {
    width: 40vw;
    height: 60vh;
    right: 0px;
    z-index: 100;
    object-fit: contain;
    object-position: center;
    position: absolute;
    display: block;
    padding: 20px;
}

.row-custom-10 {
  margin-left: -10px;
  margin-right: -10px;
  .col-custom-10 {
      padding-left: 10px;
      padding-right: 10px;
  }
}

.row-custom-8 {
  margin-left: -8px;
  margin-right: -8px;
  .col-custom-8 {
      padding-left: 8px;
      padding-right: 8px;
  }
}

.row-custom-0 {
  margin-left: -0px;
  margin-right: -0px;
  .col-custom-0 {
      padding-left: 0px;
      padding-right: 0px;
  }
}


/*custom start*/

@media (max-width: 1199px) {
    .section-contact {
        .contact-form-box {
            .form-padding {
                padding-right: 0px;
            }
        }
    }

    .link-custom-line.icon-social {
        display: none;
    }

    .about-milkita {
        min-height: 600px;
    }

    .jus-milkita-home {
        position: relative;

        img {
            position: absolute;
            right: 15%;
            bottom: -100px;
            height: 500px;
        }
    }

    .susu1-milkita-home {
        position: relative;

        img {
            position: absolute;
            height: 320px;
            right: 15%;
            margin-right: 16px;
            margin-top: 65px;
        }
    }

    .animation-section {
        min-height: inherit;
    }

    .br-min-off {
        br {
            display: none;
        }
    }
}

@media (max-width: 992px) {
    .container-custom {
        padding: 30px 100px;
    }

    .container-custom.candy-milk {
        padding: 30px 80px;
    }

    .navbar {
        padding: 0px 100px;
    }

    .contact-form-box {
        min-height: 1000px;
    }

    .navbar {
        .navbar-brand {
            img {
                height: 70px;
            }
        }
    }

    .navbar-collapse.collapsing,
    .navbar-collapse.collapse.show {
        background-color: rgba(255, 255, 255, .98);
        border-top: 6px solid $color-green;
        border-radius: 30px;

        .navbar-nav {
            padding: 15px 25px;
            border-radius: 30px;

            .link-custom-line {
                .nav-link {
                    color: $color-primary;
                    padding: 5px 0px;
                    font-weight: 600;
                    line-height: 1;
                }

                .nav-link::after {
                    display: none;
                }

                .img-menu {
                    height: 45px;
                    margin-left: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .jus-milkita-home {
        position: relative;

        img {
            position: absolute;
            right: 10%;
            bottom: -80px;
            height: 450px;
        }
    }

    .susu1-milkita-home {
        position: relative;

        img {
            position: absolute;
            height: 300px;
            right: 10%;
            margin-right: 10px;
            margin-top: 35px;
        }
    }

    .section-candy1 {
        .img-candy1b {
            margin-top: 100px;
        }
    }

    .shape-footer {
        display: none;
    }

}

@media (max-width: 767px) {
    .container-custom {
        padding: 20px 60px;
    }

    .container-custom.candy-milk {
        padding: 20px 40px;
    }

    footer {

        .footer-section,
        .footer-section-bottom {
            padding: 20px 60px;
        }
    }

    .navbar {
        padding: 0px 60px;
    }

    .section-candy1 {
        .img-candy1b {
            margin-top: 0px;
        }
    }

    .section-candy2 {
        .img-candy2b {
            margin-top: 20px
        }
    }

    .navbar {
        .navbar-brand {
            img {
                height: 60px;
            }
        }
    }

    .contact-form-box {
        min-height: 1000px;
    }
}

@media (max-width: 575px) {
    .section-about {
        .about-title {
            height: auto;
            width: 100%;
        }
    }

    .section-products {
        .products-title {
            height: auto;
            width: 100%;
        }
    }

    .section-contact {
        .contact-title {
            height: auto;
            width: 100%;
        }
    }

    h1,
    .font-40 {
        font-size: 28px !important;
    }

    .container-custom {
        padding: 10px 20px;
    }

    .container-custom.candy-milk {
        padding: 10px 10px;
    }

    footer {

        .footer-section,
        .footer-section-bottom {
            padding: 20px 20px;
        }
    }

    .contact-form-box {
        min-height: 800px;
    }

    .social-td {
        display: block;
    }

    .navbar {
        padding: 0px 30px;
    }

    .jus-milkita-home {
        position: relative;

        img {
            position: absolute;
            right: 10%;
            bottom: -50px;
            height: 300px;
        }
    }

    .susu1-milkita-home {
        display: none;
    }

    .navbar {
        .navbar-brand {
            img {
                height: 55px;
            }
        }
    }
}

@media (max-width: 380px) {}